<template lang="">
    <div>
        <Monthly/>
    </div>
</template>
<script>
export default {
    components: {
    Monthly: () => import('@/components/lists/Monthly.vue'),
}
}
</script>
<style lang="">
    
</style>